import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionBenefits from '../../components/home/SectionBenefits/SectionBenefits';
import Sectioncomparison from '../../components/home/SectionComparison/Sectioncomparison';
import SectionExperts from '../../components/home/SectionExperts/SectionExperts';
import SectionFaq from '../../components/home/SectionFaq/SectionFaq';
import SectionFasilitas from '../../components/home/SectionFasilitas/SectionFasilitas';
import SectionFasilitators from '../../components/home/SectionFasilitators/SectionFasilitators';
import SectionIntro from '../../components/home/SectionIntro/SectionIntro';
import SectionInvest from '../../components/home/SectionInvest/SectionInvest';
import SectionJoin from '../../components/home/SectionJoin/SectionJoin';
import SectionMateri from '../../components/home/SectionMateri/SectionMateri';
import SectionMentors from '../../components/home/SectionMentors/SectionMentors';
import SectionProjects from '../../components/home/SectionProjects/SectionProjects';
import SectionTestimonials from '../../components/home/SectionTestimonials/SectionTestimonials';
// import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const Home = () => {
    const MySwal = withReactContent(Swal)

    const [alertShown, setAlertShown] = useState(sessionStorage.getItem('alertShown') === 'true');

    useEffect(() => {
        if (!alertShown) {
            setTimeout(() => {
                MySwal.fire({
                    height: 400,
                    width: 'auto',
                    padding: 0,
                    background: 'transparent',
                    backdrop: "rgba(0,0,0,0.6)",
                    showCloseButton: true,
                    showConfirmButton: false,
                    html: `
                        <a href="http://pay.codepolitan.com/?slug=program-fullstack-web-development-lifetime&coupon=KFLEBARAN" target="_blank" rel="noopener noreferrer">
                            <img width="600" height="400" class="img-fluid" src="https://image.web.id/images/Group-7840.png" alt="Promo" />
                        </a>
                    `,
                    // html: `
                    //     <a href="https://form.cdpl.link/miniclass" target="_blank" rel="noopener noreferrer">
                    //         <img width="600" height="400" class="img-fluid" src="https://image.web.id/images/Group-3861.png" alt="Mini Class" />
                    //     </a>
                    // `,
                });
                sessionStorage.setItem('alertShown', 'true');
                setAlertShown(true);
            }, 20000);
        };
    }, [MySwal, alertShown]);

    return (
        <>
            <Helmet>
                <title>Kelas Online Fullstack Developer From A to Z - KelasFullstack.id</title>
            </Helmet>
            <Layout>
                <Hero />
                <SectionIntro />
                <SectionJoin />
                <SectionFasilitas />
                <SectionMateri />
                <SectionProjects />
                <SectionBenefits />
                <Sectioncomparison />
                <SectionMentors />
                <SectionFasilitators />
                <SectionExperts />
                <SectionTestimonials />
                <SectionInvest />
                <SectionFaq />
            </Layout>
        </>
    );
};

export default Home;
